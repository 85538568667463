@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Store Typography */
  .text-h1 {
    @apply font-inter text-[60px] leading-[60px] font-bold
      text-center text-store-darkgray;
  }
  .text-h2 {
    @apply font-inter text-[24px] leading-[32px] desktop:text-[36px] desktop:leading-[40px] text-store-darkgray;
  }
  .text-header {
    @apply font-inter text-[22px] font-bold text-store-darkgray;
  }
  .text-body {
    @apply font-inter text-[16px] leading-[24px] desktop:text-[18px] desktop:leading-[28px] text-store-darkgray;
  }
  .modal-container .text-h2 {
    @apply text-[24px] leading-[32px];
  }
  .modal-container .text-body {
    @apply text-store-bluegray mt-[10px];
  }
  .questionnaire #user_answer_form .text-h2 {
    @apply text-[24px];
  }
  .questionnaire #user_answer_form .text-sm-body {
    @apply text-[16px] leading-[24px];
  }
  .text-body-bold {
    @apply font-inter font-bold text-[14px] leading-[20px] desktop:text-[18px] desktop:leading-[28px] text-store-darkgray;
  }
  .text-sm-body {
    @apply font-inter text-[14px] leading-[20px] desktop:text-[16px] desktop:leading-[24px] text-store-darkgray;
  }
  .text-sm-body-semibold {
    @apply text-sm-body font-semibold;
  }
  .text-sm-body-bold {
    @apply text-sm-body font-bold;
  }
  .text-xs-body {
    @apply font-inter text-[12px] leading-[16px] desktop:text-[14px] desktop:leading-[20px] text-store-darkgray;
  }
  .text-xs-body-bold {
    @apply text-xs-body font-bold;
  }
  .text-xs-body-light {
    @apply text-xs-body font-light;
  }
  .text-tag {
    @apply font-inter text-[10px] leading-[16px] text-store-darkgray;
  }
  .text-cta {
    @apply font-inter text-[14px] leading-[20px] text-store-darkgray desktop:text-[16px] desktop:leading-[24px];
  }
  .error-text {
    @apply font-inter text-[12px] font-normal leading-[15px] text-left text-store-error;
  }
  /* Member's Portal Typography */
  .mb-text-body {
    @apply font-inter text-[13px] tablet:text-[16px] leading-[22px] text-black;
  }
}

.store-link,
a {
  @apply font-inter text-[12px] leading-[15px] tablet:text-[14px] tablet:leading-[19px] hover:text-store-primary transition-all;
}
