#checkout {
  * {
    @apply font-mundial;
  }

  .questionnaire-container {

    input:not([type="checkbox"]),
    select {
      @apply bg-transparent border-2 border-[#d9d9d9] text-black placeholder-black placeholder-opacity-90 w-full px-5 py-4 min-w-full shadow-none rounded-lg font-bold focus:border-black focus:ring-0;
    }
  }

  label {
    @apply text-sm mt-4;
  }

  .heading {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    letter-spacing: -0.02em;
    @apply w-[305px] lg:w-[500px] max-w-full font-termina mx-auto mb-6 lg:mb-14 font-bold;

    &.sm {
      font-size: 20px;
    }

    &.lg {
      @apply text-[25px] lg:text-[32px];
    }
  }
}

.checkout.confirmation .questionnaire-container {
  margin-bottom: 2.5rem !important;
}
